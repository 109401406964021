import {HelmetComponent} from "../components/Layout";
import Header from "../components/Header";
import {MainSiteWrapper} from "../components/_mainComponents";
import React from "react";
import {PageWrapper, Wrapper} from "../styles/_components";
import Meme from "../components/Meme";
import {graphql} from "gatsby";
import VideoPlayer from "../components/VideoPlayer";

const Memes = ({data} : any) => {
    return (
        <MainSiteWrapper className="mainWrapper">
            <HelmetComponent />
            <Header />
            <PageWrapper>
                <Wrapper>
                    {[...data.images.edges, ...data.videos.edges].sort((a,b)=>a.node.name.localeCompare(b.node.name)).map(meme=>{
                        if(meme.node.hasOwnProperty("childImageSharp")){
                            return <Meme data={meme} key={meme.node.id}/>
                        }else{
                            return <VideoPlayer data={meme.node}/>
                        }
                    })}
                </Wrapper>
            </PageWrapper>
        </MainSiteWrapper>
    );
};

export const indexQuery = graphql`
    query DucksPhotos {
        images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)|(webp)/"}, dir: {regex: "/src/images/ducks/"}}) {
            edges {
                node {
                    id
                    name
                    size
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                            layout: FULL_WIDTH
                        )

                    }
                }
            }
        }
        videos: allFile(filter: {extension: {regex: "/(gif)|(mp4)|(webm)/"}, dir: {regex: "/src/images/ducks/"}}) {
            edges {
                node {
                    id
                    name
                    size
                    publicURL
                    extension
                }
            }
        }
    }
`

export default Memes;